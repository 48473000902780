<template>
  <ConfirmDialog />
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '550px' }"
    :header="
      data_cita.id ? 'EDITAR CITA: ' + data_cita.nombre_cliente : 'NUEVA CITA'
    "
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12">
          <span
            ><strong>BUSCAR PACIENTE</strong
            ><span class="p-invalid">*</span></span
          >
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-user"></i>
            </span>
            <AutoComplete
              v-model="data_cita.nombre_cliente"
              :suggestions="clientes"
              field="nombre"
              v-tooltip.top="'Buscar paciente'"
              optionValue="id"
              @keyup.enter="buscarCliente"
            />
            <Button
              icon="pi pi-search"
              class="p-button-warning p-button-sm"
              v-tooltip.top="'Buscar paciente'"
              @click="buscarCliente"
            />
            <Button
              icon="pi pi-plus"
              class="p-button-info p-button-sm"
              v-tooltip.top="'Agregar paciente'"
              @click="activarCliente"
            />
          </div>
          <small class="p-invalid" v-if="errors.nombre">{{
            errors.nombre[0]
          }}</small>
        </div>
        <div class="col-12 md:col-8">
          <span
            ><strong
              >SELECCIONE UN MÉDICO <span class="p-invalid">*</span>
            </strong></span
          >
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-user"></i>
            </span>
            <Dropdown
              v-model="data_cita.medico_id"
              :disabled="existe_medico_logueado"
              :options="medicos"
              optionLabel="nombre_completo"
              optionValue="id"
              placeholder="Seleccione un médico"
              :filter="true"
              v-tooltip.top="'Seleccione un médico'"
            />
          </div>
          <small class="p-invalid" v-if="errors.medico_id">{{
            errors.medico_id[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <span><strong>CONSULTORIO:</strong></span>
          <Dropdown
            v-model="data_cita.consultorio_id"
            :options="consultorios"
            optionLabel="nombre"
            optionValue="id"
            placeholder="Seleccione..."
            v-tooltip.top="'Consultorio'"
          />
          <small class="p-invalid" v-if="errors.consultorio_id">{{
            errors.consultorio_id[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4 mt-2">
          <span
            ><strong
              >FECHA DE LA CITA <span class="p-invalid">*</span></strong
            ></span
          >
          <Calendar
            v-model="data_cita.fecha"
            dateFormat="dd/mm/yy"
            v-tooltip.top="'Fecha de la cita'"
          />
          <small class="p-invalid" v-if="errors.fecha">{{
            errors.fecha[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4 mt-2">
          <span
            ><strong
              >HORA DE LA CITA <span class="p-invalid">*</span></strong
            ></span
          >
          <InputText
            v-model="data_cita.hora"
            placeholder="Hora de la cita"
            v-tooltip.top="'Hora de la cita'"
            maxlength="5"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode == 58"
          />
          <small class="p-invalid" v-if="errors.hora">{{
            errors.hora[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4 mt-2">
          <span
            ><strong
              >DURACIÓN DE LA CITA <span class="p-invalid">*</span></strong
            ></span
          >
          <Dropdown
            v-model="data_cita.duracion"
            :options="duraciones_citas"
            optionLabel="label"
            optionValue="value"
            placeholder="DURACIÓN DE LA CITA"
            v-tooltip.top="'Duración de la cita'"
          />
          <small class="p-invalid" v-if="errors.duracion">{{
            errors.duracion[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-12 mt-2">
          <span
            ><strong
              >MOTIVO DE LA CITA <span class="p-invalid">*</span>
            </strong></span
          >
          <Textarea
            v-model="data_cita.detalle"
            rows="2"
            autoResize
            placeholder="Motivo de la cita"
            v-tooltip.top="'Motivo de la cita'"
          />
          <small class="p-invalid" v-if="errors.detalle">{{
            errors.detalle[0]
          }}</small>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-success"
        @click="ocultarDialog"
      />
      <Button
        v-if="data_cita.id && data_cita.estado == 1"
        label="ELIMINAR"
        icon="pi pi-trash"
        class="p-button-danger"
        @click="eliminarCita"
        v-tooltip.top="'Eliminar cita'"
        :loading="eliminando"
      />
      <Button
        v-if="data_cita.estado == 1"
        label="ATENDER"
        icon="pi pi-user"
        class="p-button-warning"
        @click="atenderCita"
        v-tooltip.top="'Atender cita'"
        :loading="atendiendo"
      />
      <Button
        v-if="data_cita.estado != 2"
        :label="data_cita.id ? 'ACTUALIZAR' : 'GUARDAR'"
        :icon="data_cita.id ? 'pi pi-pencil' : 'pi pi-save'"
        class="p-button-info"
        @click="guardarCita"
        v-tooltip.top="data_cita.id ? 'Actualizar cita' : 'Guardar cita'"
        :loading="enviando"
      />
    </template>
  </Dialog>
  <ClienteCreate
    :show="clienteDialog"
    :cliente="cliente"
    @actualizarListado="obtenerClientes"
    @closeModal="cerrarClienteDialog"
  />
</template>

<script>
import AgendaService from "@/service/AgendaService";
import ClienteCreate from "@/module/clientes/ClienteCreate.vue";
import ClienteService from "@/service/ClienteService";

export default {
  components: {
    ClienteCreate,
  },
  emits: ["actualizarListado", "closeModal"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    cita: {
      type: Object,
      default: () => {
        return {
          fecha: "",
          hora: "",
        };
      },
    },
    consultorios: {
      type: Array,
      default: () => [],
    },
    medicos: {
      type: Array,
      default: () => [],
    },
    medicoLogueado: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      errors: {},
      mostrarModal: this.show,
      enviando: false,
      data_cita: this.cita,
      clientes: [],
      clienteDialog: false,
      cliente: {
        estado: { label: "Activo", value: 1 },
      },
      existe_medico_logueado: this.medicoLogueado,
      duraciones_citas: [
        //avanza cada 30 minutos
        { label: "30 minutos", value: 30 },
        { label: "1 hora", value: 60 },
        { label: "1 hora 30 minutos", value: 90 },
        { label: "2 horas", value: 120 },
      ],
      eliminando: false,
      atendiendo: false,
      color: null,
    };
  },
  agendaService: null,
  auth: null,
  clienteService: null,
  created() {
    this.agendaService = new AgendaService();
    this.clienteService = new ClienteService();
  },
  methods: {
    atenderCita() {
      let datos = {
        id: this.data_cita.id,
        nombre_cliente: this.data_cita.nombre_cliente,
        cliente_id: this.data_cita.cliente_id,
      };
      this.$router.push({
        name: "plantratamientos",
        params: {
          nombre_cliente: datos.nombre_cliente,
          cliente_id: datos.cliente_id,
        },
      });
      this.atendiendo = true;
      this.agendaService.updateEstadoAgenda(datos).then((data) => {
        if (data.success) {
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: data.message,
            life: 800,
          });
          this.atendiendo = false;
          this.$emit("actualizarListado");
          this.ocultarDialog();
        } else {
          this.atendiendo = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: data.message,
            life: 800,
          });
        }
      });
    },
    buscarCliente() {
      let datos = {
        texto: this.data_cita.nombre_cliente,
      };
      this.clienteService.buscarXNombre(datos).then((response) => {
        if (response.status == 400 || response.clientes.length == 0) {
          this.$toast.add({
            severity: "error",
            summary: "ERROR",
            detail: "No se encontró el cliente",
            life: 300,
          });
          return;
        }
        this.clientes = [...response.clientes];
      });
    },
    activarCliente() {
      this.clienteDialog = true;
      this.cliente = {
        estado: { label: "Activo", value: 1 },
        nombre: this.data_cita.nombre_cliente ?? "",
      };
    },
    cerrarClienteDialog() {
      this.clienteDialog = false;
    },
    obtenerClientes(selected = null) {
      this.clienteService
        .getClientesAll()
        .then((response) => {
          if (selected) {
            this.data_cita.cliente_id = selected.id;
            this.clientes = [];
          } else {
            this.clientes = response;
          }
        })
        .catch((error) => console.log(error));
    },
    dateFormatted(date) {
      //que la primera letra sea mayuscula Lunes 01 de Enero de 2021 a las 10:00 hrs
      let fecha = new Date(date);
      let options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      let fecha_format = fecha.toLocaleDateString("es-ES", options);

      return fecha_format.charAt(0).toUpperCase() + fecha_format.slice(1);
    },
    ocultarDialog() {
      this.$emit("closeModal");
      this.mostrarModal = false;
      this.data_cita = {
        fecha: "",
        hora: "",
        duracion: 30,
        detalle: "",
        cliente_id: null,
        nombre_cliente: "",
        medico_id: null,
        nombre_medico: "",
        consultorio_id: null,
        nombre_consultorio: "",
      };
      this.errors = {};
      this.enviando = false;
      this.eliminando = false;
      this.atendiendo = false;
    },
    validarFormulario() {
      this.errors = {};
      if (!this.data_cita.nombre_cliente) {
        this.errors.nombre = ["El campo nombre es requerido"];
      }
      if (!this.data_cita.medico_id) {
        this.errors.medico_id = ["El campo médico es requerido"];
      }
      if (!this.data_cita.fecha) {
        this.errors.fecha = ["El campo fecha es requerido"];
      }
      if (!this.data_cita.hora) {
        this.errors.hora = ["El campo hora es requerido"];
      }
      if (!this.data_cita.duracion) {
        this.errors.duracion = ["El campo duración es requerido"];
      }
      if (!this.data_cita.detalle) {
        this.errors.detalle = ["El campo detalle es requerido"];
      }
      return Object.keys(this.errors).length === 0;
    },
    guardarCita() {
      if (!this.validarFormulario()) {
        return;
      }
      this.errors = {};
      this.enviando = true;
      if (this.data_cita.id) {
        let datos = {
          ...this.data_cita,
          cliente_id: this.data_cita.cliente_id,
          medico_id: this.data_cita.medico_id,
          consultorio_id: this.data_cita.consultorio_id || null,
          nombre_consultorio: this.data_cita.consultorio_id
            ? this.consultorios.find(
                (consultorio) => consultorio.id == this.data_cita.consultorio_id
              ).nombre
            : "",
          nombre_cliente: this.data_cita.nombre_cliente,
          nombre_medico: this.medicos.find(
            (medico) => medico.id == this.data_cita.medico_id
          ).nombre_completo,
          fecha_inicio: `${this.data_cita.fecha} ${this.data_cita.hora}`,
          duracion: this.data_cita.duracion,
          color: this.data_cita.color ? this.data_cita.color : "#007dd7",
        };
        this.agendaService.updateAgenda(datos).then((data) => {
          if (data.success) {
            this.$toast.add({
              severity: "success",
              summary: "Éxito",
              detail: data.message,
              life: 800,
            });
            this.enviando = false;
            this.$emit("actualizarListado");
            this.ocultarDialog();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: data.message,
              life: 800,
            });
            this.enviando = false;
          }
        });
        return;
      } else {
        let datos = {
          ...this.data_cita,
          cliente_id: this.data_cita.nombre_cliente.id,
          medico_id: this.data_cita.medico_id,
          consultorio_id: this.data_cita.consultorio_id || null,
          nombre_consultorio: this.data_cita.consultorio_id
            ? this.consultorios.find(
                (consultorio) => consultorio.id == this.data_cita.consultorio_id
              ).nombre
            : "",
          nombre_cliente: this.data_cita.nombre_cliente.nombre,
          nombre_medico: this.medicos.find(
            (medico) => medico.id == this.data_cita.medico_id
          ).nombre_completo,

          fecha_inicio: `${this.data_cita.fecha} ${this.data_cita.hora}`,
          duracion: this.data_cita.duracion,
          color: this.data_cita.color ? this.data_cita.color : "#007dd7",
        };
        this.agendaService.saveAgenda(datos).then((data) => {
          if (data.success) {
            this.$toast.add({
              severity: "success",
              summary: "Éxito",
              detail: data.message,
              life: 800,
            });
            this.loading = false;
            this.$emit("actualizarListado");
            this.ocultarDialog();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: data.message,
              life: 800,
            });
            this.loading = false;
          }
        });
      }
    },
    eliminarCita() {
      this.$confirm.require({
        message: "¿Está seguro de eliminar la cita?",
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.eliminando = true;
          this.agendaService.deleteAgenda(this.data_cita.id).then((data) => {
            if (data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Éxito",
                detail: data.message,
                life: 800,
              });
              this.eliminando = false;
              this.$emit("actualizarListado");
              this.ocultarDialog();
            } else {
              this.eliminando = false;
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: data.message,
                life: 800,
              });
            }
          });
        },
      });
    },
  },
  watch: {
    show(newVal) {
      this.mostrarModal = newVal;
    },
    cita(newVal) {
      this.data_cita = newVal;
    },
    medicoLogueado(newVal) {
      this.existe_medico_logueado = newVal;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}
</style>
